.list-title-and-list {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}

.list-narrow {
    width: 350px;
}

.list-model-select{
    width: 350px;
}

.list-wide {
    width: 400px;
}

.list-short {
    height: 400px;
}

.list-vshort {
    height: 350px;
}
.list-vvshort {
    height: 250px;
}
.list-extremely-short {
    height: 150px;
}

.list-full-height {
    height: 100%;
}

.list-title-and-buttons {
    display: flex;
    justify-content: space-between;

    margin-left: 5px;
    margin-right: 10px;
    height: 40px;
}

.list-title {
    font-weight: bold;

    margin-top: 4px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.list-buttons {
    display: flex;
    justify-content: flex-end;
    min-height: 40px;
}

.list-buttons-short {
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    min-height: 20px;
}

.list-top-button {
    margin-left: 5px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 3px;
}

.list-filter {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-right: 8px;
}

.list-container {
    width: 100%;
    height: 100%;
}

.list-background-style {
    background-color: #EEEEEE;
    border: 1px solid black;
    border-radius: 5px;
}

.select-list-item {
    background-color: #EEEEEE;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.select-list-item-not-selected {
    color: black;
    background-color: #f0f0f0 !important;

}

.select-list-item-selected {
    color: white;
    background-color: #007bff !important;

}

.tabs-container {
    flex-grow: 2;
    align-self: flex-end;
    margin-left: 0px;
}


.nav-tabs .nav-link {
    background-color: transparent !important;
    color: #000000 !important;
    border-top: 1px solid black !important;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
}

.nav-tabs .nav-link.active {
    border-bottom: 1px solid rgb(255, 255, 255) !important;
}

.project-top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0px;
    padding-bottom: 10px;

}

.project-select-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0px;
    margin-right: 0px;
    padding-bottom: 5px;
    padding-right: 5px;
    border-bottom: 1px solid black;
}


.list-item {
    display: flex;
    flex-direction: column;
    padding-top: 7px;
    margin-top: 0px;
    padding-left: 8px;
    padding-right: 8px;

    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-radius: 5px;
}

.list-item-not-selected {
    color: black;
    background-color: #fafafa !important;
}

.list-item-selected {
    color: white;
    background-color: #007bff !important;
}

.list-item-highlighted {
    color: black;
    background-color: #d4d4d4 !important;
}

.list-item-warning {
    background-color: #fd5757 !important;
}

.list-item-title {
    font-weight: bold;
}

.list-item-split-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.list-item-split-row-spaced {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.list-item-split-row-space-around {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.list-item-split-row-item-left {
    float: left
}

.list-item-split-row-item-right {
    float: right
}

.list-item-normal-item {
    font-size: 14px;
}

.list-item-sub-item {
    font-size: 12px;
}

.org-info-main-panel {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
}

.org-info-left-col {
    display: flex;
    flex-direction: column;
    width: 50px;
    margin-left: 5px;
}

.org-info-right-col {
    display: flex;
    flex-direction: column;
}