
.swatch-container-small {
    box-sizing: border-box;
    margin-top: 6px;
    z-index: 1;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border: 1px solid #000;
    transform: translate(0%, 0%);
}

.swatch-container-big {
    box-sizing: border-box;
    z-index: 1;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 2px solid #000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translate(0%, 0%);
    cursor: pointer;
}

.swatch-container-alpha-small {
    background-color: #fff;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill-opacity=".1"><rect x="4" width="4" height="4"/><rect y="4" width="4" height="4"/></svg>');
}
.swatch-container-alpha {
    background-color: #fff;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".1"><rect x="8" width="8" height="8"/><rect y="8" width="8" height="8"/></svg>');
}

.swatch-container-fill {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    border-radius: inherit;
}

.z-behind {
    z-index: 0 !important;
}