.modal-1000w {
    width: 1000px;
    max-width: 1000px;
}

.modal-1200w {
    width: 1200px;
    max-width: 1200px;
}

.modal-900w {
    width: 1000px;
    max-width: 1000px;
}

.modal-800w {
    width: 800px;
    max-width: 800px;
}
.modal-700w {
    width: 700px;
    max-width: 700px;
}

.subtitle{
    font-size: 12px;
}