.nav-bar {
    margin: 0px;
    padding: 0px;
    border-bottom: 1px solid black;
    height: 50px;
    background: #EEEEEE;
}

.nav-logo-container {
    height: 49px;
    display: flex;
    flex-direction: row;

    border-radius: 5px;

    margin: 0px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.nav-logo {
    margin-top: 3px;
    margin-left: 10px;
}

.home-text {
    margin-top: 6px;
    margin-left: 25px;
    margin-right: 25px;
}

.nav-menu-container {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.user-menu {
    margin-top: 10px;
    margin-right: 10px;
}

.orgs-menu {
    margin-top: 10px;
    margin-right: 25px;
}

.help-menu {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 25px;
}

.tooltip-style {
    color: black !important;
    background-color: #c8c8c8ff !important;
    opacity: 1 !important;
}

.dropdown-item {
    min-width: 300px !important;
}