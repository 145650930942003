.page-bottom-panel-flex {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 75px);
}

.page-bottom-panel {
    height: calc(100vh - 75px);
}

.page-left-panel {
    height: calc(100vh - 90px);

    position: absolute;
    left: 0;
    top: 50;
    display: block;
}

.panel-narrow{
    width: 400px;
}

.panel-wide{
    width: 400px;
}

.page-content-panel {
    margin-left: 400px;
    height: calc(100vh - 90px);
    display: flex;
    flex-wrap: wrap;
}

.page-content-panel-flex {
    height: calc(100vh - 90px);
    display: flex;
    flex-wrap: wrap;
}


