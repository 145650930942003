$ka-background-color: #ffffff;
$ka-border-color: #000000;

$ka-color-base: #000000;
$ka-input-background-color: $ka-background-color;
$ka-input-border-color: $ka-border-color;
$ka-input-color: $ka-color-base;
$ka-row-hover-background-color: transparentize(#000000, 0.9);
$ka-thead-background-color: #eeeeee;
$ka-thead-color: #000000;
$ka-column-resize-border-color: #7d7d7d;
$ka-header-cell-height: 47px !default;

$ka-cell-padding: 5px 5px !default;
$ka-thead-cell-padding: 5px 18px !default;
$ka-row-border-size: 1px !default;

.ka-thead-grouped-cell {
    border: 1px solid black;
}
.ka-row {
    box-sizing: border-box;    
    border-top: $ka-row-border-size solid $ka-border-color;
}
.ka-cell {
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.ka-filter-row-cell {
    border: 1px solid black;
}

.ka-thead-cell {
    padding: $ka-thead-cell-padding;
    color: $ka-thead-color;
}

.ka-thead-cell-wrapper {
    display: flex;
    height: 100%;
    flex-wrap: nowrap;
    align-content: center;
    margin: 0px;
    padding: 0px;
    align-items: center;
}

.ka-thead-cell-content-wrapper {
    width: 100%;

}

.ka-thead-cell-content {

    width: 100%;

    vertical-align: middle;
}


.ka-thead-cell-resize {
    user-select: none;
    width: 2px;
    cursor: col-resize;
    background-color: $ka-column-resize-border-color;
    position: relative;
    left: 19px;
    height: 100%;
}

.ka-thead-cell-resize:active:after {
    content: "";
    display: block;
    position: fixed;
    cursor: col-resize;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}