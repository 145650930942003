.steplist {
    border: 1px solid rgb(164, 164, 164);
    box-sizing: border-box;
    border-radius: 5px;

    background: white;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 5px;
    padding-right: 9px;
    padding-bottom: 9px;
    padding-left: 10px;

    max-height: 80%;
    min-height: 480px;
    height: 500px;
    max-height: auto;
    overflow-y: auto;


    width: 510px;
    box-shadow: 0px 0px 0px 0px red;
}

.modal-850w {
    width: 850px;
    max-width: 850px;
}

.steplist-glow-red {
    box-shadow: 0px 0px 5px 5px red;
}

.isDraggingOverStepList {
    background: rgb(196, 233, 196);

}

.stepTypeList {
    border: 1px solid rgb(162, 162, 162);
    box-sizing: border-box;
    border-radius: 5px;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 0px;
    padding-right: 9px;
    padding-bottom: 7px;
    padding-left: 10px;

    width: 200px
}

.crs-graph-container {
    width: 100%;
    height: calc(100vh - 220px);
}

.crs-graph {
    border: 1px solid black;
    border-radius: 5px;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    background: #EEEEEE;
    z-index: 0 !important;
}

.crs-node {
    width: 100px;
    height: 80px;
}

.crs-node-buttons-panel {

    margin: 0px;

    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 9px;
    padding-left: 0px;

    width: 100px;
    height: 20px;


    transition: ease .1s;
    transform: translate(0, 100%)
}

.crs-node-buttons-panel-closed {
    transform: translate(0, 100%)
}

.crs-node-buttons-panel-open {
    transform: translate(0%, 0);
}

.crs-node-buttons-panel-parent {
    height: 20px;
    width: 100px;
    padding: 0px;
    margin: 0px;
}

.crs-node-title {

    font-size: 6px;
    font-weight: bold;
    white-space: normal;
    text-align: left;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: hidden;


    border: 1px solid black;
    border-bottom: 1px solid black;

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    padding-top: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;

    height: auto;
    min-height: 20px;
    width: 100px;

    background: #EEEEEE;
}


.crs-node-body {
    border: 1px solid black;

    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    font-size: 6px;
    white-space: normal;
    text-align: left;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: hidden;
    text-overflow: ellipsis;

    padding-top: 3px;
    padding-right: 3px;
    padding-bottom: 3px;
    padding-left: 3px;

    height: auto;
    min-height: 40px;
    width: 100px;

    background: #EEEEEE;
}

.icon-button {

    float: right;

    border: 1px solid black;
    background-color: #EEEEEE;
    color: black;
    border-radius: 2px;

    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: 0px;
    margin-left: 2px;

    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;

    cursor: pointer;
    width: 18px;
    height: 18px;
}

.button-icons {
    vertical-align: middle;

    width: 12px;
    height: 12px;
}


.custom-edge-button {
    background-color: white;
    border: 1px solid rgba(99, 98, 98, 0.575);
    color: rgb(66, 66, 66);

    width: 18px;
    height: 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 50%;
    line-height: 18px;

    margin-top: 0px;
    margin-bottom: 3px;
    margin-right: 0px;
    margin-left: 1px;

    padding: 0;
    font-size: 12px;
}

.custom-edge-icon {
    background-color: white;
    border: 0px;
    color: rgb(66, 66, 66);
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    margin-top: 0px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 1px;

    padding: 0px;

}

.node-button-icon {
    margin-bottom: 12px;
}

.edge-button-icon {
    margin-bottom: 6px;
}

.custom-edge-panel-parent {
    width: 88px;
    height: 26px;

    margin: 0px;
    padding-bottom: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-edge-button-panel {
    height: 25px;
    border: 1px solid rgb(99, 98, 98);
    border-radius: 12px;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 1px;
    padding-left: 1px;

    background: white;

    transition: width 200ms;
}

.custom-edge-button-panel-closed {
    width: 24px;
}

.custom-edge-button-panel-open-small {

    width: 44px;
}

.custom-edge-button-panel-open-large {

    width: 82px;
}



.custom-edge-foreignobject {
    color: rgba(0, 0, 0, 0);
    background-color: rgba(0, 0, 0, 0);
}

.text-line-breaks {
    white-space: pre-line;
}
