.group-everything {
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 75px);
}


.group-title-and-list {
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.group-title-and-buttons {
    display: flex;
    justify-content: space-between;
    width: 350px;
    margin-left: 5px;
}

.group-title {
    font-weight: bold;

    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 0px;
    margin-left: 0px;

}

.group-buttons {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;

    margin-top: 5px;
    margin-bottom: 5px;
}

.group-top-button {
    margin-left: 5px;
    padding: 3px;
}


.group-list {
    width: 350px;
    height: calc(100% - 50px);}

.group-filter {
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-right: 8px;
}