.errorcontainer {
    display: flex;
    flex-direction: column;
}

.errorMessageContainer {
    display: flex;
    flex-direction: row;
}

.errorMessageLabel {
    margin-left: 25vw;
    width: 10vw;
}

.errorMessageItem {
    width: 40vw;
}

.errorHeader {
    text-align: left;
    margin-left: 25vw;
}

.errorItem {
    width: 50vw;
    text-align: left;
    margin-left: 25vw;
}

.errorStack {
    width: 50vw;
    text-align: left;
    border: 1px solid black;
    margin-left: 25vw;
}

hr.dashed-line{
    width: 50vw;
    border-top: 3px dashed #000000;
    margin-left: 25vw;
}

.retry-button{
    margin-left: 25vw;
}