ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.transformStepTypeItem {
    margin-top: 10px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 10px;

    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 5px;

    background: #EEEEEE;
}


.transformStepTypeItemDragging {
    margin-top: 10px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 10px;

    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 2px;

    background: #EEEEEE;
}

.styleRuleItemDragging {
    margin-top: 10px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 10px;

    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 5px;

    overflow: visible;

    background: #c2c2c2;
    font-size: 12;
    white-space: "nowrap";
    text-overflow: "ellipsis";
    overflow-x: "hidden";
}

.draggingItemToDelete {
    background: rgb(248, 159, 159);

}

.hideDropAnimation {
    display: none !important;
}

.react-beatiful-dnd-copy~div {
    transform: none !important;
}

.react-beatiful-dnd-copy {
    background: white;
}

.react-beatiful-dnd-copy~[data-rbd-placeholder-context-id] {
    display: none !important;
}
