.user-info {
    margin-left: 8px;
    display: flex;
    flex-wrap: wrap;
}

.user-details {
    margin-left: 8px;
    width: 530px;
    display: flex;
    flex-direction: column;
    height: 170px;
}

.user-details-title-container {
    display: flex;
    justify-content: space-between;
    margin-left: 5px;
}

.user-details-title {
    font-weight: bold;

    margin-top: 4px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.label {
    width: 180px;
}

.content {
    width: 350px;
}