:root {
    --amplify-primary-color: #0000AA;
    --amplify-primary-tint: #000099;
    --amplify-primary-shade: #000099;
}

.amplify-button[data-variation='primary'] {
    background: #0000AA;
}

.dev-display {
    font-weight: bold;
    margin-right: 15px;
}

