.toast-from {
    font-weight: bold;
}

.toast-message-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    margin: 0px;
    padding: 0px;
    align-items: center;
    justify-content: space-between;
}

.toast-contents-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    margin: 0px;
    padding: 0px;
    align-items: left;
    justify-content: space-between;
}

.toast-button {
    margin-left: 15px;
}

.toast-icon {
    margin-right: 15px;
}

.toast-icons {
    vertical-align: middle;

    width: 30px;
    height: 30px;
}