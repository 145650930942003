.list-property-select {
    margin: 0;
    padding: 0;
    min-width: 145px;
    font-size: 75%;

}


.annotations-view {

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;

    width: 100%;
}

.view-top-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    margin-top: 5px;
    padding: 0px;
}

.view-back-button {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 5px;

}

.view-title {
    font-weight: bold;
    padding-top: 10px;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.view-button-panel {
    margin-right: 5px;
    padding: 0px;
}

.annotation-view-button-panel-button {
    margin-right: 5px;
}

.annotation-table-filter-container {
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
}

.annotation-table-filter-container-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.annotation-table-filter-operator-number {
    width: 40px;
    margin-right: 5px;
    margin-bottom: 2px;
}

.annotation-table-filter-operator-text {
    width: 100px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
}

.annotation-table-filter-number {
    width: 60px;
    margin-bottom: 2px;
}

.annotation-table-filter-text {
    width: 100%;
    margin-bottom: 2px;
}

.annotation-table-filter-date {
    width: 105px;
    margin-bottom: 2px;
}

.table-cell-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}