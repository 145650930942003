.log-table-area {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
        
}


.log-tabs {
    border: 1px solid black;
    padding: 5px;
}

.close-button {
    float: right;
    width: 100px;
}

.log-view-top-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;

    margin-top: 5px;
    padding: 0px;
}

.log-view-title {
    font-weight: bold;
    padding-top: 10px;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}


.log-view-button-panel {
    margin-right: 5px;
    padding: 0px;    
}

.log-view-button-panel-button {
    margin-top: 5px;
    float: right;
    width: 190px;
}