.project-graph-title {

    float: left;
    font-weight: bold;

    margin-top: 8px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

}

.border-bottom-only {
    border-bottom: 1px solid black;
}

.modal-500w {
    width: 500px;
    max-width: 500px;
}

.modal-z-order {
    z-index: 200;
}

.z-behind {
    z-index: 0 !important;
}

.styleRuleItem {
    margin-top: 10px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 10px;

    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 5px;

    overflow: visible;

    background: #efefef;
    font-size: 10;
    white-space: "nowrap";
    text-overflow: "ellipsis";
    overflow-x: "hidden";

}

.styleRuleItemSelected {
    margin-top: 10px;
    margin-bottom: 6px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 8px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-left: 10px;

    border: 1px solid black;
    box-sizing: border-box;
    border-radius: 5px;

    overflow: visible;
    color: white;
    background: rgba(13, 110, 253);
    font-size: 12;
    white-space: "nowrap";
    text-overflow: "ellipsis";
    overflow-x: "hidden";

}

.annotationPropertyItemContainer {
    overflow: visible;
    display: flex;
    align-items: center;
}

.annotationPropertyItemContainerBackgroundLight {
    background-color: #FFFFFF;
}

.annotationPropertyItemContainerBackgroundDark {
    background-color: #b5b5b5;
}

.annotationPropertyItemLabel {
    margin-right: 10px;
    font-size: 100%;
    font-weight: normal;
    flex-grow: 1;
}

.annotationPropertyItemPin {
    flex-grow: 1;
}

.annotationPropertyItemValue {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 65%;
}

.styleRuleContents {
    display: flex;
    flex-direction: column;
}

.styleRuleSubject {
    display: flex;
}



.parameter-display {
    display: flex;
}

.colour-display {
    display: flex;
}

.popover {
    position: absolute;
    top: calc(100% - 22px);
    left: 100;
    border-radius: 9px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.edit-style-sheet-modal {
    width: 1000px;
    max-width: 1000px;
}


.styleRuleList {

    border: 1px solid rgb(162, 162, 162);
    box-sizing: border-box;
    border-radius: 5px;
    background: white;

    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    margin-left: 0px;

    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
    padding-left: 10px;

    max-height: 500px;
    min-height: 500px;
    height: 500px;
    max-height: auto;
    overflow-y: auto;

    width: 450px;
    box-shadow: 0px 0px 0px 0px red;
}

.isDraggingOverStyleRule {
    background: rgb(196, 233, 196);

}