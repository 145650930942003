.annotation-table-styles {
    @import "./table-theme.scss";
    @import "ka-table/style.scss";

    .ka-table-wrapper {
        height: calc(100vh - 215px);
        width: 100%;
        overflow-y: auto;
        margin: 0px;
        padding: 5px;
    }
}

.log-table-styles {
    @import "./table-theme.scss";
    @import "ka-table/style.scss";

    .ka-table-wrapper {
        height: calc(100vh - 215px);
        width: 100%;
        overflow-y: auto;
        margin: 0px;
        padding: 5px;
    }
}